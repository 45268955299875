<template>
  <div id="client-workouts">
    <!--HEADER-->
    <div class="workout-header">
      <vs-button type="flat" :to="{name:'programs'}" icon="keyboard_backspace">返回列表</vs-button>
    </div>

    <!-- WORKOUTS -->
    <vx-card class="workout-list">
      <div>
        <div class="name-action">
          <h2 v-if="program">{{program.name}}</h2>
          <span v-if="program">{{program.description}}</span>
        </div>
        <div class="name-action">
          <vs-button
            type="flat"
            icon="edit"
            style="float:right"
            @click="popupProgramEdit=true"
          >编辑基本信息</vs-button>
          <vs-popup title="新建标准训练计划表" :active.sync="popupProgramEdit">
            <ProgramEdit :programOrg="program" @hide="popupProgramEdit=false" />
          </vs-popup>
        </div>
      </div>
      <vs-row
        v-for="(week, indexWeek) in weekRange"
        :key="indexWeek"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="stretch"
        vs-w="12"
      >
        <vs-col
          v-for="(day, indexDay) in week"
          :key="indexDay"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="1.71"
          class="w-l-i-item"
        >
          <ProgramWorkout
            :position="day.position"
            :workoutOrg="day.workout"
            :program="program"
            mode="display"
          />
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="flex-start" vs-w="12" class="bottom-action">
        <vs-button icon="add" @click="plusWeek()">增加一周</vs-button>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
// import DTUtil from "@/assets/utils/datetime.js";

import ProgramEdit from "@/views/components/coach/program/ProgramEdit.vue";
import ProgramWorkout from "@/views/components/coach/program/ProgramWorkout.vue";

// //sample data
import { mapState } from "vuex";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

export default {
  mixins: [baseFun],
  data() {
    return {
      program: null,
      popupProgramEdit: false,
      weekNum: 1
    };
  },

  created() {
    let program = this.getProgram(this.$route.params.id);
    // this.program = JSON.parse(JSON.stringify(program));
    this.program = program;
    if (this.program && this.program.workout_ids) {
      // load all workout
      this.$store.dispatch("getWorkouts", {ids: this.program.workout_ids});

      //get week number
      this.weekNum = this.programPositionToWeeks(this.program.highest_position);

      this.length = this.updateLength();
    }

    //pre-load all libs for editing
    this.$store.dispatch("getExercises");
    this.$store.dispatch("getCooldowns");
    this.$store.dispatch("getWarmups");
  },

  computed: {
    // 显示的整周
    weekRange() {
      let weeks = [];
      for (let i = 0; i < this.weekNum; i++) {
        let week = [];
        for (let j = 1; j <= 7; j++) {
          let position = i * 7 + j;
          week.push({position:position, workout:this.getMyWorkout(position)});
        }
        weeks.push(week);
      }
      return weeks;
    },

    // programWorkouts() {
    //   let program = this.program;
    //   return this.workouts.filter(item =>
    //     program.workout_ids.includes(item.id)
    //   );
    // },

    ...mapState([
      "activeTrainer",
      "organizations",
      "trainers",
      "clients",
      "users",
      "programs",
      "program_assignments",
      "workouts",
      "workout_items",
      "activeTrainer",
      "activeUser",
      "activeOrganization",
      "comments"
    ])
  },

  // watch:{
  //   '$route' (to, from){
  //     console.log(to, from);
  //   }
  // },

  methods: {
    getMyWorkout(position) {
      let program = this.program;
      return this.workouts.find(
        item => program.id == item.program_id && item.position == position
      );
    },

    plusWeek() {
      this.weekNum += 1;
    },

    updateLength(){
      this.program.length = this.weekNum * 7;
    },

    refresh(){
      //this.program = JSON.parse(JSON.stringify(this.program));
      // this.$forceUpdate();
    }
  },

  components: {
    ProgramWorkout,
    ProgramEdit
  }
};
</script>

<style>
.goto-buttons {
  height: 38px;
}

.dt-popup .vs-popup {
  width: auto !important;
}
</style>
<style scoped>
.name-action {
  width: 50%;
  float: left;
}
/* day workout */
.w-l-i-item {
  min-height: 150px;
  border-left: 1px solid #dee1e4;
  border-right: 1px solid #dee1e4;
  border-bottom: 1px solid #dee1e4;
  padding: 0px;
}

.bottom-action {
  margin-top: 15px;
}
</style>
